.dot {
	background-color: darkgray !important;
	opacity: 0.9 !important;
}

.dot:focus {
	outline: 2px solid orange;
}

.dot.selected {
	background-color: #AE45EE !important;
}